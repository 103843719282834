export type LogicalOperator = 'AND' | 'OR';
export type Operator = '=' | '<=' | '>=' | '>' | '<' | 'LIKE' | 'IN' | 'NOT IN';
export type ConditionParams = { column: string; operator: Operator; value: string | number | boolean };
export type Conditions = ConditionParams | LogicalOperator | ConditionsList;
export type ConditionsList = Array<Conditions>;

export default function useReportFilter() {
  const getComulnName = (column: string) => {
    switch(column) {
      case 'vehicleRegistration':
        return 'vehicle.registration' // tutaj nazwa kolumny w bazie
      // case '...':
      // itd...
      default:
        return column
    }
  }

  const getOperator = (operator: string) => {
    switch(operator) {
      case 'LIKE':
        return 'ILIKE' // bo postgres
      default:
        return operator
    }
  }

  const getFilter = (filter: ConditionsList): {
    query: string;
    params: Array<ConditionParams['value']>;
  } => {
    let query = ''
    const params: Array<ConditionParams['value']> = []

    const parseFilters = (filter: ConditionsList) => {
      query += '('
      for (const item of filter) {
        if (Array.isArray(item)) parseFilters(item)
        else if (typeof item === 'object' && item !== null) {
          if (item.column && item.operator && item.value) {
            params.push(item.operator === 'LIKE' ? `%${item.value}%` : item.value)
            query += `${getComulnName(item.column)} ${getOperator(item.operator)} $${params.length}`
          }
        } else if (typeof item === 'string') {
          query += ` ${item} `
        }
      }
      query += ')'
    }

    parseFilters(filter)

    return { query, params }
  }

  const validateFilter = (filter: ConditionsList) => {
    const errors: string[] = []

    const checkFilterList = (filter: ConditionsList) => {
      for (const item of filter) {
        if (Array.isArray(item)) checkFilterList(item)
        else if (typeof item === 'object' && item !== null) {
          if (!item.column) errors.push('ERR_COLUMN_NOT_PROVIDED')
          if (!item.operator) errors.push('ERR_OPERATOR_NOT_PROVIDED')
          if (!item.value) errors.push('ERR_VALUE_NOT_PROVIDED')
        }
      }
    }

    checkFilterList(filter)

    const isValid = errors.length <= 0

    return { isValid, errors }
  }

  return { getFilter, validateFilter }
}
