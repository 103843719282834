






































































































































































































































import { defineComponent, reactive, ref } from "@vue/composition-api";
import useReportFilter, { ConditionsList } from "@/use/reportFilter";
import { AxiosInstance } from "node_modules/axios";
import fileDownload from "js-file-download";
import mime from "mime-types";
import moment from "moment";
import useUser from "@/use/user";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
    OReportFilter: () => import("@/components/organisms/o-report-filter.vue"),
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
  },

  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });

    const form = ref<any>(null);

    const { getFilter, validateFilter } = useReportFilter();

    const state = reactive({
      tab: 0,
      valid: true,
      filters: {
        service: { valid: false },
        insurance: { valid: false },
        leasing: { valid: false },
        fuelCard: { valid: false },
      },
      error: null as string | null,
      success: false,
      loading: false,
      menu: {
        from: false,
        to: false,
      },
    });

    const model = reactive<{
      company: null | any;
      from: null | string;
      to: null | string;
      service: {
        enabled: boolean;
        columns: {
          [key: string]: boolean;
        };
        filter: ConditionsList;
      };
      insurance: {
        enabled: boolean;
        columns: {
          [key: string]: boolean;
        };
        filter: ConditionsList;
      };
      leasing: {
        enabled: boolean;
        columns: {
          [key: string]: boolean;
        };
        filter: ConditionsList;
      };
      fuelCard: {
        enabled: boolean;
        columns: {
          [key: string]: boolean;
        };
        filter: ConditionsList;
      };
    }>({
      company: null,
      from: null as null | string,
      to: null as null | string,
      service: {
        enabled: true,
        columns: {
          vehicleRegistration: true,
          vehicleVinNumber: true,
          vehicleModel: true,
          vehicleEngineCapacity: true,
          vehicleEmissionStandard: true,
          vehicleMileage: true,
          vehicleDriver: true,
          vehicleComment: true,

          serviceType: true,
          serviceDescription: true,
          serviceDate: true,
          serviceAttachment: true,
          serviceTireStoragePlace: true,
          serviceCost: true,
        },
        filter: [],
      },
      insurance: {
        enabled: true,
        columns: {
          vehicleRegistration: true,
          vehicleVinNumber: true,
          vehicleModel: true,
          vehicleEngineCapacity: true,
          vehicleEmissionStandard: true,
          vehicleMileage: true,
          vehicleDriver: true,
          vehicleComment: true,

          insuranceEndDate: true,
          insuranceAttachment: true,
          insurancePremium: true,
        },
        filter: [],
      },
      leasing: {
        enabled: true,
        columns: {
          vehicleRegistration: true,
          vehicleVinNumber: true,
          vehicleModel: true,
          vehicleEngineCapacity: true,
          vehicleEmissionStandard: true,
          vehicleMileage: true,
          vehicleDriver: true,
          vehicleComment: true,

          leasingEndDate: true,
          leasingMileage: true,
          leasingInstallment: true,
        },
        filter: [],
      },
      fuelCard: {
        enabled: true,
        columns: {
          vehicleRegistration: true,
          vehicleVinNumber: true,
          vehicleModel: true,
          vehicleEngineCapacity: true,
          vehicleEmissionStandard: true,
          vehicleMileage: true,
          vehicleDriver: true,
          vehicleComment: true,

          productName: true,
          productType: true,
          date: true,
          invoiceNumber: true,
          type: true,
          price: true,
          amount: true,
          total: true,
        },
        filter: [],
      },
    });

    const rules: { [key: string]: ((v: any) => string | boolean)[] } = {
      from: [
        (v) => !!v || "Podaj datę od której ma zostać wygenerowany raport",
      ],
      to: [
        (v) => !!v || "Podaj datę do której ma zostać wygenerowany raport",
        (v) =>
          !model.from ||
          (model.from &&
            new Date(v).getDate() >= new Date(model.from).getDate()) ||
          'Data "do" nie może być wcześniejsza od daty "od"',
      ],
    };

    const submit = async () => {
      await form.value?.validate();

      state.filters.service.valid = validateFilter(
        (model.service as any).filter
      ).isValid;
      state.filters.insurance.valid = validateFilter(
        (model.insurance as any).filter
      ).isValid;
      state.filters.leasing.valid = validateFilter(
        (model.leasing as any).filter
      ).isValid;
      state.filters.fuelCard.valid = validateFilter(
        (model.fuelCard as any).filter
      ).isValid;

      if (
        state.valid &&
        state.filters.service.valid &&
        state.filters.insurance.valid &&
        state.filters.leasing.valid &&
        state.filters.fuelCard.valid
      ) {
        const $axios = root.$store.getters["api/getInstance"] as AxiosInstance;

        state.error = null;
        state.loading = true;

        const data = {
          company: model.company?.id || undefined,
          from: model.from,
          to: model.to,
          service: model.service,
          insurance: model.insurance,
          leasing: model.leasing,
          fuelCard: model.fuelCard,
        };

        await $axios
          .post("report", data, { responseType: "blob" })
          .then(({ data }) => {
            fileDownload(
              data,
              `RAPORT_CleverFleet_${moment().format(
                "YYYY-MM-DD_HH-mm-ss"
              )}.${mime.extension(data.type)}`
            );
            state.success = true;
          })
          .catch(console.log)
          .finally(() => (state.loading = false));
      } else {
        state.error = "Uzupełnij wymagane pola.";
      }
    };

    const addRuleGroup = (item: any[]) => {
      if (item.length >= 1) item.push("AND");
      item.push([]);
    };

    const addRule = (item: any[]) => {
      if (item.length >= 1) item.push("AND");
      item.push({ column: null, operator: null, value: null });
    };

    const reset = () => {
      model.company = null;
      model.from = null;
      model.to = null;

      const service = model.service as any;
      service.enabled = true;
      service.filter = [];
      Object.keys(service.columns).forEach(
        (column) => (service.columns[column] = true)
      );

      const insurance = model.insurance as any;
      insurance.enabled = true;
      insurance.filter = [];
      Object.keys(insurance.columns).forEach(
        (column) => (insurance.columns[column] = true)
      );

      const leasing = model.leasing as any;
      leasing.enabled = true;
      leasing.filter = [];
      Object.keys(leasing.columns).forEach(
        (column) => (leasing.columns[column] = true)
      );

      const fuelCard = model.fuelCard as any;
      fuelCard.enabled = true;
      fuelCard.filter = [];
      Object.keys(fuelCard.columns).forEach(
        (column) => (fuelCard.columns[column] = true)
      );

      state.tab = 0;
      state.error = null;
      state.success = false;
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      form,
      state,
      model,
      rules,
      submit,
      addRuleGroup,
      addRule,
      getFilter,
      reset,
    };
  },
});
